import React from "react";
import { Link } from "gatsby";
import Arrow from "../../images/icon-arrow-simple.svg";
import Logo from "../../images/logo-shield.svg"

// Start over function
function handleStartOver() {
  window.location.reload(false);
}

const Sidebar = ({ pageContext }) => (
  <section id="sidebar" className={`sidebar sidebar-${pageContext}`}>
    <div className="sidebar__details">
      <div className="flex-row sidebar__details-logo">
        <img src={Logo} alt={""} className="logo" />
        {/* <p className="light-16">Calculate Subsidy</p> */}
      </div>
      <div className="arrow-container">
        <img src={Arrow} alt="" className="arrow-simple" />
      </div>
      <div className="sidebar__details-copy">
        <p className="condensed-bold-14 headline">Subsidy Tool</p>
        <h1 className="bold-26">
          Health Insurance Marketplace Subsidy Calculator
        </h1>
      </div>
      <div className="sidebar__link-wrap">
        <Link
          to="/"
          onClick={handleStartOver}
          className="btn btn--transparent btn--start-over bold-14"
        >
          Start Over
        </Link>
        <Link className="btn btn--transparent bold-14 btn--faqs" to="/faqs">
          FAQs
        </Link>
        <Link
          className="btn btn--transparent btn--calculator-return bold-14"
          to="/"
        >
          Return to Calculator
        </Link>
      </div>
    </div>
    <div className="sidebar__footer"></div>
  </section>
);

export default Sidebar;
